<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        :label-col="{ span: 4, offset: 2 }"
        :wrapper-col="{ span: 8 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-form-item label="会议名称">
          <a-input
            v-decorator="[
              'meetName',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="督办事项">
          <a-input
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="督办类型">
          <a-cascader
            :options="typeList"
            placeholder=""
            :fieldNames="{
              label: 'name',
              value: 'value',
              children: 'children',
            }"
            v-decorator="[
              'type',
              {
                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item>
          <span slot="label" class="required">执行人</span>

          <div v-if="selectedUser.length > 0">
            <div
              class="selected-user"
              v-for="(user, index) in selectedUser"
              :key="user.userId"
            >
              <a-button block style="text-align: left">
                <div v-if="user.name">
                  {{ user.deptUniqueName }} -
                  {{ user.name }}
                </div>
                <div v-else>&nbsp;</div>
              </a-button>
              <EmployeeSelector
                :single="false"
                v-if="index === selectedUser.length - 1"
                @change="
                  (arr) => {
                    selectedUser = arr;
                  }
                "
                :value="selectedUser"
              >
                <a-button
                  size="small"
                  type="primary"
                  shape="circle"
                  icon="plus"
                  style="position: absolute; top: 8px; right: -32px"
                ></a-button>
              </EmployeeSelector>
              <a-button
                size="small"
                type="danger"
                shape="circle"
                icon="minus"
                :style="{
                  position: 'absolute',
                  top: '8px',
                  right: index !== selectedUser.length - 1 ? '-32px' : '-62px',
                }"
                @click="removeUser(index)"
              ></a-button>
            </div>
          </div>
          <div class="selected-user" v-else>
            <EmployeeSelector
              :single="false"
              @change="
                (arr) => {
                  selectedUser = arr;
                }
              "
              :value="selectedUser"
            >
              <a-button block style="text-align: left">
                <div>&nbsp;</div>
              </a-button>
              <a-button
                size="small"
                type="primary"
                shape="circle"
                icon="plus"
                style="position: absolute; top: 8px; right: -32px"
              ></a-button>
            </EmployeeSelector>
          </div>
        </a-form-item>

        <a-form-item label="办结时限">
          <a-date-picker
            v-decorator="[
              'originalDate',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
            style="width: 100%"
            placeholder=""
          />
        </a-form-item>

        <a-form-item label="动态追踪">
          <div
            v-for="(item, index) in tracksList"
            :key="index"
            style="position: relative"
          >
            <a-date-picker
              style="width: 100%"
              placeholder=""
              v-model="item.trackDate"
            />

            <a-button
              v-if="index === tracksList.length - 1"
              size="small"
              type="primary"
              shape="circle"
              icon="plus"
              style="position: absolute; top: 8px; right: -32px"
              @click="addTrack"
            ></a-button>
            <a-button
              v-if="index !== 0"
              size="small"
              type="danger"
              shape="circle"
              icon="minus"
              :style="{
                position: 'absolute',
                top: '8px',
                right: index !== tracksList.length - 1 ? '-32px' : '-62px',
              }"
              @click="deleteTrack(index)"
            ></a-button>
          </div>
        </a-form-item>

        <a-form-item label="办结要求">
          <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['remarks']" />
        </a-form-item>

        <a-form-item>
          <span slot="label" class="">相关附件</span>
          <div class="link-list">
            <a-checkbox-group :value="selectedFileList" @change="onSelectFile">
              <div class="link" v-for="item in fileList" :key="item.id">
                <a-checkbox :value="item.id">
                  <a target="_blank" download :href="item.completePath">{{
                    item.name
                  }}</a>
                </a-checkbox>
              </div>
            </a-checkbox-group>

            <div class="control-bar">
              <FileUpload @uploaded="uploaded">
                <div class="control">
                  <a-icon type="upload" />
                </div>
              </FileUpload>

              <div class="control" @click="deleteFile">
                <a-icon type="delete" />
              </div>
            </div>
          </div>
        </a-form-item>

        <a-form-item :wrapper-col="{ offset: 6 }">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="loading"
              >提交</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { add } from "@/api/supervise";
import { mapGetters } from "vuex";
import moment from "moment";
import FileUpload from "@/components/file-upload";
import EmployeeSelector from "@/components/employee-selector";

export default {
  name: "addSupervise",

  components: {
    FileUpload,
    EmployeeSelector,
  },

  data() {
    return {
      form: this.$form.createForm(this),

      tracksList: [
        {
          trackDate: null,
        },
      ], // 动态时间节点列表
      fileList: [], // 上传的文件
      selectedFileList: [], // 选择的文件

      loading: false,

      selectedUser: [], // 选择的执行人
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    // 督办类型
    typeList() {
      return this.findDataDict("dblx");
    },

    // 提示语集合
    messageList() {
      return this.findDataDict("message");
    },
  },

  methods: {
    removeUser(index) {
      this.selectedUser.splice(index, 1);
    },

    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },

    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
    },

    addTrack() {
      this.tracksList.push({
        trackDate: null,
      });
    },
    deleteTrack(index) {
      this.tracksList.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.check(values);
        }
      });
    },

    check(values) {
      if (this.selectedUser.length === 0) {
        this.$message.error("请选择执行人！");
        return;
      }

      if (moment().isSameOrAfter(values.originalDate)) {
        this.$message.error("办结时限不得早于今日！");
        return;
      }

      let errorFlag = false;
      let earlyFlag = false;

      for (let i = 0; i < this.tracksList.length; i++) {
        const item = this.tracksList[i];
        if (!item.trackDate) {
          continue;
        }

        let res = moment(
          values.originalDate.format("YYYY-MM-DD")
        ).isSameOrBefore(moment(item.trackDate.format("YYYY-MM-DD")));

        if (res) {
          errorFlag = true;
          break;
        }
        if (moment().isSameOrAfter(item.trackDate)) {
          earlyFlag = true;
          break;
        }
      }

      if (errorFlag) {
        this.$message.error("动态追踪时间不能晚于办结时限！");
        return;
      }
      if (earlyFlag) {
        this.$message.error("动态追踪时间不能早于当前时间！");
        return;
      }

      const that = this;

      this.$confirm({
        title: this.messageList.find(
          (item) => item.value === "supervise-allocate"
        ).name,
        onOk() {
          that.continue(values);
        },
      });
    },

    continue(values) {
      this.loading = true;

      add({
        ...values,

        assignees: this.selectedUser.map((user) => ({
          assigneeId: user.userId,
          assigneeName: user.name,
          assigneeDeptId: user.deptId,
          assigneeDeptName: user.deptUniqueName,
        })),

        originalDate: values.originalDate.format("YYYY-MM-DD"),

        tracksList: this.tracksList
          .filter((item) => item.trackDate)
          .map((item) => ({
            trackDate: item.trackDate.format("YYYY-MM-DD"),
          })),

        type: values.type[0],
        subType: values.type[1],

        // 附件
        fileIdList:
          this.fileList.length > 0
            ? this.fileList.map((item) => item.id)
            : undefined,
      })
        .then(() => {
          this.form.resetFields();
          this.selectedUser = [];
          this.tracksList = [
            {
              trackDate: null,
            },
          ];
          this.selectedFileList = [];
          this.fileList = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}

.selected-user {
  position: relative;
}
</style>
