var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4, offset: 2 },"wrapper-col":{ span: 8 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"会议名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'meetName',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'meetName',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"督办事项"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'name',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"督办类型"}},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'type',
            {
              rules: [{ required: true, message: '请选择！' }],
            },
          ]),expression:"[\n            'type',\n            {\n              rules: [{ required: true, message: '请选择！' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"options":_vm.typeList,"placeholder":"","fieldNames":{
            label: 'name',
            value: 'value',
            children: 'children',
          }}})],1),_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("执行人")]),(_vm.selectedUser.length > 0)?_c('div',_vm._l((_vm.selectedUser),function(user,index){return _c('div',{key:user.userId,staticClass:"selected-user"},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(user.name)?_c('div',[_vm._v(" "+_vm._s(user.deptUniqueName)+" - "+_vm._s(user.name)+" ")]):_c('div',[_vm._v(" ")])]),(index === _vm.selectedUser.length - 1)?_c('EmployeeSelector',{attrs:{"single":false,"value":_vm.selectedUser},on:{"change":(arr) => {
                  _vm.selectedUser = arr;
                }}},[_c('a-button',{staticStyle:{"position":"absolute","top":"8px","right":"-32px"},attrs:{"size":"small","type":"primary","shape":"circle","icon":"plus"}})],1):_vm._e(),_c('a-button',{style:({
                position: 'absolute',
                top: '8px',
                right: index !== _vm.selectedUser.length - 1 ? '-32px' : '-62px',
              }),attrs:{"size":"small","type":"danger","shape":"circle","icon":"minus"},on:{"click":function($event){return _vm.removeUser(index)}}})],1)}),0):_c('div',{staticClass:"selected-user"},[_c('EmployeeSelector',{attrs:{"single":false,"value":_vm.selectedUser},on:{"change":(arr) => {
                _vm.selectedUser = arr;
              }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[_c('div',[_vm._v(" ")])]),_c('a-button',{staticStyle:{"position":"absolute","top":"8px","right":"-32px"},attrs:{"size":"small","type":"primary","shape":"circle","icon":"plus"}})],1)],1)]),_c('a-form-item',{attrs:{"label":"办结时限"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'originalDate',
            { rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'originalDate',\n            { rules: [{ required: true, message: '请选择！' }] },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"动态追踪"}},_vm._l((_vm.tracksList),function(item,index){return _c('div',{key:index,staticStyle:{"position":"relative"}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"placeholder":""},model:{value:(item.trackDate),callback:function ($$v) {_vm.$set(item, "trackDate", $$v)},expression:"item.trackDate"}}),(index === _vm.tracksList.length - 1)?_c('a-button',{staticStyle:{"position":"absolute","top":"8px","right":"-32px"},attrs:{"size":"small","type":"primary","shape":"circle","icon":"plus"},on:{"click":_vm.addTrack}}):_vm._e(),(index !== 0)?_c('a-button',{style:({
              position: 'absolute',
              top: '8px',
              right: index !== _vm.tracksList.length - 1 ? '-32px' : '-62px',
            }),attrs:{"size":"small","type":"danger","shape":"circle","icon":"minus"},on:{"click":function($event){return _vm.deleteTrack(index)}}}):_vm._e()],1)}),0),_c('a-form-item',{attrs:{"label":"办结要求"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remarks']),expression:"['remarks']"}],attrs:{"auto-size":{ minRows: 3 }}})],1),_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("相关附件")]),_c('div',{staticClass:"link-list"},[_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)]),_c('a-form-item',{attrs:{"wrapper-col":{ offset: 6 }}},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("提交")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }